import ClipboardJS from 'clipboard'
import intlTelInput from 'intl-tel-input'

const copyCodesButton = document.getElementById('recovery-codes-copy')
if (copyCodesButton) {
  new ClipboardJS('#recovery-codes-copy')
  const copyDiv = document.getElementById('copy-codes')
  const copiedDiv = document.getElementById('copied-codes')

  copyCodesButton.onclick = () => {
    copyDiv.classList.add('hidden')
    copiedDiv.classList.remove('hidden')

    setTimeout(() => {
      copyDiv.classList.remove('hidden')
      copiedDiv.classList.add('hidden')
    }, 2500)
  }
}

function loadCountryTranslationsAsync(lang) {
  return import(`@/src/country_data/${lang}.json`)
}

async function postPhoneNumber(phoneNumber, inputField) {
  const headers = {
    'Content-Type': 'application/json'
  }

  const crsf = document.head.querySelector('meta[name="csrf-token"]')
  if (crsf) headers['X-CSRF-Token'] = crsf.content

  const body = {}
  body[inputField.name] = phoneNumber

  const passwordField = document.getElementById('password')
  if (passwordField) body['password'] = passwordField.value

  const response = await fetch(inputField.form.action, {
    headers: headers,
    method: 'POST',
    body: JSON.stringify(body)
  })

  return await response.json()
}

function initPhoneInput(inputField, translations) {
  const options = {
    initialCountry: inputField.dataset.country,
    localizedCountries: translations,
    preferredCountries: []
  }

  // Scripts are not needed in test and specs need more setup and tweaks to fetch them correctly,
  // so just don't include them.
  if (import.meta.env.MODE != 'test') {
    options['utilsScript'] = 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js'
  }

  const phoneInput = intlTelInput(inputField, options)

  phoneInput.setNumber(inputField.value)

  inputField.form.onsubmit = async (event) => {
    event.preventDefault()

    setLoading(inputField.form)

    const confirmForm = document.getElementById('sms-confirm-form')

    const errorDivs = document.getElementsByClassName("invalid-feedback")
    for (let i = 0; i < errorDivs.length; i++) {
      errorDivs[i].classList.add('hidden')
    }

    phoneInput.setNumber(inputField.value)

    const formattedPhoneNumber = phoneInput.getNumber()

    const data = await postPhoneNumber(formattedPhoneNumber, inputField)

    unsetLoading(inputField.form)

    if (data['field-error']) {
      const errorDiv = document.getElementById(`${data['field-error'][0]}_error_message`)
      errorDiv.innerHTML = data['field-error'][1]
      errorDiv.classList.remove('hidden')
    } else {
      confirmForm.querySelector('#sms-code').disabled = false
      confirmForm.querySelector('button[type="submit"]').disabled = false
    }
  }
}

const phoneInputField = document.getElementById("sms-phone")
if (phoneInputField) {
  loadCountryTranslationsAsync(document.head.querySelector('meta[name="app-data"]').dataset.i18nLocale).then(module => initPhoneInput(phoneInputField, module.default))
}

const alertCloseButton = document.getElementById('alert-close')
if (alertCloseButton) {
  alertCloseButton.onclick = () => document.getElementById('alert-el').classList.add('hidden')
}

const showOtpKeyButton = document.getElementById('otp-no-scan')
if (showOtpKeyButton) {
  showOtpKeyButton.onclick = () => document.getElementById('otp-user-key-manual').classList.toggle('hidden')
}

const languagePicker = document.getElementById('language-picker')
if (languagePicker) {
  function toggleLanguageMenu() {
    document.getElementById('language-chevron-down').classList.toggle('hidden')
    document.getElementById('language-chevron-up').classList.toggle('hidden')
    document.getElementById('language-menu').classList.toggle('hidden-transition')
  }

  languagePicker.onclick = toggleLanguageMenu

  window.onclick = function(event) {
    if (event.target.closest('#language-picker')) return
    if (document.getElementById('language-menu').classList.contains('hidden-transition')) return

    toggleLanguageMenu()
  }
}

function setLoading(form) {
  const submitButton = form.querySelector('button[type="submit"]')
  if (submitButton) {
    submitButton.readOnly = true
    submitButton.classList.add('loading')
    submitButton.querySelector('span').classList.add('hidden')
    submitButton.querySelector('svg').classList.remove('hidden')
  }
}

function unsetLoading(form) {
  const submitButton = form.querySelector('button[type="submit"]')
  if (submitButton) {
    submitButton.readOnly = false
    submitButton.classList.remove('loading')
    submitButton.querySelector('span').classList.remove('hidden')
    submitButton.querySelector('svg').classList.add('hidden')
  }
}

document.querySelectorAll('form').forEach(form => {
  if (form.onsubmit) next

  form.onsubmit = () => setLoading(form)
})
